
import { Component, Vue } from 'vue-property-decorator';
import { getProductList } from '@/api/product';
import Pagination from '@/components/Pagination/index.vue';
import { storageKey } from '@/enums/localStorage';
import { UserModule } from '@/store/modules/user';

@Component({
	name: 'ProductRotation',
	components: {
		Pagination,
  },
})

export default class extends Vue {
	private loading = true;

	private products: any = [];

	private selectedShop = JSON.parse((window as any).localStorage.getItem(storageKey.pickUpPlace));

	private apiUrl = process.env.VUE_APP_COMMON_API;

	mounted() {
		if (!UserModule.isLogin) {
			this.$message.info('로그인이 필요한 서비스 입니다.');
			this.$router.push({ name: 'Login' });
		}
		this.handleProductList();
	}

	private listQuery = {
		page: 1,
		size: 10,
		searchType: 'name',
		searchValue: '',
		productType: 'STATION',
		extraProductStatus: false,
	}

	private totalElements = 0;

	handleProductList() {
		this.loading = true;
		getProductList(this.listQuery).then((res) => {
			this.totalElements = res.data.totalElements;
			this.products = res.data.content;
			this.loading = false;
		});
	}

	handleChoice(product: any) {
		this.$router.push({
			name: 'CustomProductDetail',
			params: {
				productIdx: product.idx,
			},
		});
	}
}
